import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import ContactCaptcha from '../../components/contactContainer';

const ContactContainer = ({ location }) => {
  const { pathname } = location;
  const filter = pathname.split('/').filter((pathString) => pathString.length > 0).pop();

  return (
    <Layout
      title="Get In Touch | Wow Interior Design"
      description="Contact Wow Interior Design by phone, email or by using our forms for enquiries, a free no obligation design visit, virtual design visit or request a free brochure."
    >
      <Menu />
      <ContactCaptcha formType={filter} />
    </Layout>
  );
};

export default ContactContainer;

ContactContainer.propTypes = {
  location: PropTypes.object.isRequired,
};
